import { Schema, isDef, isObject, isArray, revive_dates_recursively } from './Schema';
import { get_feedback, get_feedback_sql } from './feedback';
import { fill_template } from './template';
//const { ChartDef } = require('./ChartDef');
const FormulaParser = require('hot-formula-parser').Parser;
import type { ChartDef } from './ChartDef';
import type { IfLevelSchema } from './IfLevelSchema';


// Are we on the server or on the client?
function is_server(): boolean {
	return ! (typeof window !== 'undefined' && window.document );
}

interface IHistory { 
	[key: string]: any
	code?: String
	dt?: Date
}

function ErrorIfUndefined( o?: any): any {
	if( typeof o == 'undefined') throw new Error('Error: Undefined');
	return o;
}

// Ensure that this is a string
function s(a: any): string {
	if(typeof a === 'string') return a;
	if(typeof a === 'number') return ''+a;
	return ''+a;
}


// Convert dt to date if it is text.
function convert_to_date_if_string( s: any): Date {
		if(typeof s === 'string') return new Date(s);
		return s;
}

// Polyfill for testing if a number is an integer.
// Currenlty in modern browsers, but below is for IE.
// Source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isInteger#Polyfill
function isInteger(nVal: number): boolean {
    return typeof nVal === 'number' && isFinite(nVal) && nVal > -9007199254740992 && nVal < 9007199254740992 && Math.floor(nVal) === nVal;
}

function onlyUnique(value, index, array) {
	return array.indexOf(value) === index;
}


// Are the arrays similar or different?
const arrayDifferent = (a1: Array<any>, a2: Array<any>): boolean => {
	// If any nulls, then return true (as a null is an unitialized user submitted answer)
	if(a1 === null || a2 === null ) return true;

	if(a1.length !== a2.length) return true;

	for(let i=0; i<a1.length; i++) {
		if(a1[i] !== a2[i]) return true;
	}

	return false;
};


// Force boolean, not truthy or falsy.
// Allow null values.
const bool = function(unknown: any): boolean|null {
	if(unknown === null) return null;
	return unknown ? true : false;
};


/**
	noObjects checks to make sure that the passed array doesn't contain any objects other than strings or numbers.
*/
const noObjectsInArray = (i_array: Array<any>): Array<any> => {
	if(!(i_array instanceof Array)) throw new Error('noObjects can only be passed arrays.');
	i_array.forEach( v => {
		if(!(typeof v === 'string' || typeof v === 'number')) throw new Error('Invalid object passed to IfPageParsonsSchema._def_items');
	});
	return i_array;
};


// Return fields in common for all Pages.
function common_schema(): any {

	return {
		// Short-cut code used to help initialize code in server ifgame create page code.
		code: { type: 'String', initialize: (s: any) => isDef(s) ? s : null },

		// Description gives high-level conceptual overview. Useful for reviews.
		description: { type: 'String', initialize: (s: any) => isDef(s) ? s : null },
		// Instructions are specific to the given task.  
		instruction: { type: 'String', initialize: (s: any) => isDef(s) ? s : null },
		// Helpblock is given with more specific guidelines. Optional for completion.
		helpblock: { type: 'String', initialize: (s: any) => isDef(s) ? s : null },
		
		// An array of objects with changes.
		history: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? revive_dates_recursively(a) : [] },  /*defHistory*/

		// Is this correct?  True/False/Null.
		correct: { type: 'Boolean', initialize: (b: any) => isDef(b) ? bool(b) : null },

		// Do the results need to be correct to save the results?
		correct_required: { type: 'Boolean', initialize: (b: any) => isDef(b) ? bool(b) : false },

		// Feedback generated by the feedback rules. 
		// Feedback will be { has: 'values': args: [1,2,3]}
		feedback: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? a : null },

		// Feedback is the generated result, and is sent to the client.
		// Form of [ '', '' ].
		// Null if not set, array otherwise.
		client_feedback: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? a : null },

		// Template_values contains values that will be used to replace the {a} marks in 
		// any of the user-visible strings, as well as the solution.
		template_values: { type: 'Object', initialize: (o: any) => isDef(o) && isObject(o) ? o : {} },

		// After a page is completed, set TRUE so that no more updates are allowed.
		// This is done by code in server/ifGame, as the client doesn't know what the conditions are.
		completed: { type: 'Boolean', initialize: (b: any) => isDef(b) ? bool(b) : false },

		// Should we show feedback on this item after it is chosen?
		// Useful to differentiate between survey questions and those we want the user to know the right answer.
		show_feedback_on: { type: 'Boolean', initialize: (b: any) => isDef(b) ? bool(b) : true },

		// Problem Tags.
		// Used to help with analysis of question success and failure.
		// Either an empty array or an array with string tags.
		tags: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? a : [] },

		// KCs.
		// A list of strings that are used to track the type of problem.
		kcs: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? a : [] },

		// Optional time minimum. Keeps people from clicking through too quickly.
		// In seconds
		time_minimum: { type: 'Number', initialize: (s: any) => isDef(s) ? s : null },

		// Optional time limit.
		// In seconds
		time_limit: { type: 'Number', initialize: (s: any) => isDef(s) ? s : null },
		time_limit_expired: { type: 'Boolean', initialize: (b: any) => isDef(b) ? bool(b) : false },

		// Chart Definition
		chart_def: { type: 'Object', initialize: (o: any) => isDef(o) && isObject(o) ? o : null },

		// Question Unique Identifier.
		// Used to code individual questions for later analysis. Should be consistent among
		// questions, whether taken by the same person 2x, or multiple people. ID is generated
		// by the question template.
		template_id: { type: 'String', initialize: (s: any) => isDef(s) ? s : null },

		// Did the viewer look at any hints along the way?
		// If so, record the number of times.
		hints_parsed: { type: 'Number', initialize: (s: any) => isDef(s) ? s : 0 },
		hints_viewsolution: { type: 'Number', initialize: (s: any) => isDef(s) ? s : 0 },

	};
}



/**
	Common base class for shared behavior.

	Note that the ! is used to assert that these properties are all set. They are done through
	the constructor, which uses a json object as optional setup. Lots of parsing clean-up and 
	validation happens.
*/
class IfPageBaseSchema extends Schema {
	code!: string;
	description!: string;
	instruction!: string;
	template_values!: any;
	client_feedback!: string[] | null;

	feedback!: Array<Function>;
	correct!: boolean | null;
	correct_required!: boolean;
	completed!: boolean;
	show_feedback_on!: boolean;
	history!: Array<Object>;
	kcs!: Array<string>;
	time_minimum!: number;
	time_limit!: number;
	time_limit_expired!: boolean;
	chart_def!: ChartDef;
	template_id!: string;
	hints_parsed!: number;
	hints_viewsolution!: number;

	// Apply json to this obj, signaling no parent classes to do the setting for us.
	constructor( json?: any) {
		super(true);
		if(json === true) return;
		this.initialize(json, this.schema);
	}

	get type(): string {
		return 'IfPageBaseSchema';
	}

	// Must be implemented by inheriting classes.
	client_has_answered(): boolean {
		throw new Error('Inheriting classes must implement client_has_answered');
	}
	debug_answer(): any {
		throw new Error('Inheriting classes must implement debug_answer');
	}
	toString(): string {
		throw new Error('Inheriting classes must implement toString');
	}
	get_solution(): string {
		throw new Error('Inheriting classes must implement get_solution');
	}
	updateCorrect() {
		throw new Error('Inheriting classes must implement updateCorrect');
	}
	clear_answer_and_all_results(): void {
		throw new Error('Inheriting classes must implement clear_answer_and_all_results');
	}


	// This is a placeholder. It's called by IfLevleSchema to update all of the json
	// blobs of it's contained pages. This shouldn't actually be called, as the instantiated classes
	// all over-ride this method.
	updateUserFields(json: any) {
		throw new Error('Inheriting classes must implement updateUserFields and call _updateUserFields');
	}


	/*
		Used by inheriting items to update self with any matching items in passed JSON.
		Will not update a completed item.

		@json: Either updated fields, OR an entire json object from the client sent to the server.
		@valid_fields: which fields should be updated. Mandatory. Set by each different type of class.
		@update_correct: default true
		@generate_history: Will generate a history action for any updates. Default true.
	*/
	_updateUserFields(json: any, valid_fields: Array<string>, update_correct: boolean = true, generate_history: boolean = true) {
		const changes: any[] = [];

		// Make sure that we have a json
		if(typeof json === 'undefined') throw new Error('IfGames.updateUserFields(json) is null');

		// If a type, make sure it matches.
		if(typeof json.type !== 'undefined' &&
			json.type !== this.type ) throw new Error('Invalid type '+json.type+' in ' + this.type + '.updateUserFields');


		// Make sure that all passed valid_fields are present in the current object. If not true,
		// probably means that json should be called on some other object.
		valid_fields.forEach( (field: string) => {
			if(typeof this[field] === 'undefined') {
				throw new Error('Invalid field "'+field+'" passed to _updateUserFields');
			}
		});


		// don't allow updates to finished items. Note that completed isn't set internally by this obj,
		// but instead is set by the server code with knowledge of each tutorial's rules.
		// Don't throw an error upon this, as it's run by server on all pages upon receiving info from user.
		if(this.completed) return; 


		// Make sure that we have 'history' as an editable item. Otherwise, sending client back to server
		// will lose all of the updates.
		if(valid_fields.filter( s => s === 'history').length === 0) {
			valid_fields.push('history');
		}

		// Go through each valid field change to see if a change has happened.
		valid_fields.forEach( (field: string) => {
			if(typeof json[field] !== 'undefined') {

				// Is array and is different array by contents?
				if(Array.isArray(json[field])) {

					if(arrayDifferent(this[field], json[field])) {
						changes.push({ field: field, from: this[field], to: json[field] });
						this[field] = json[field];
					}

				// Or, is not an array and is different?
				} else if (json[field] !== this[field]) {
					changes.push({ field: field, from: this[field], to: json[field] });
					this[field] = json[field];
				}
			}
		});

		// If not changes, then exit.
		if(changes.length === 0) return;

		// If asked, then update the correct and add to log.
		if(update_correct) {
			// Temporarily set to = false, but this is fixed after updateCorrect() is run.
			changes.push({ field: 'correct', from: this.correct, to: false});
			this.updateCorrect();
			changes[changes.length-1].to = this.correct;
		}

		// If no history, then return.
		if(!generate_history) return;

		let history: IHistory = {};

		// Filter changes to avoid having history save all previous history changes as a change.
		// and then record each change in the new history item.
		changes
			.filter( change => change.field !== 'history')   
			.forEach( change => history[change.field] = change.to );
		
		// Reference fields.
		history.dt = new Date();
		history.code = is_server() ? 'server_update' : 'client_update';

		// Update history.
		this.history = Array.from(this.history);
		this.history.push(history);
	}

	/*
		When was this created?
		Find the oldest history item.
	*/
	get_first_update_date(): Date | null {
		const history: IHistory = this.history.filter( (h: IHistory) => h.code === 'client_update');
		return history.length > 0 
			? convert_to_date_if_string(history[0].dt)
			: null;
	}

	get_last_update_date(): Date | null {
		const history: IHistory = this.history.filter( (h: IHistory)=> h.code === 'client_update');
		return history.length > 0 
			? convert_to_date_if_string(history[history.length-1].dt)
			: null;
	}

	get_max_period_in_ms(): number {
		return 5*60*1000; // 5 minutes.
	}

	// Find out if this was abandoned.  I.E., how long did the user pause 
	// after starting before continuing.  This generally means that they were stuck.
	get_break_times_in_minutes(): Array<number> {
		const max_period = this.get_max_period_in_ms();
		const first = this.get_first_update_date();
		const last = this.get_last_update_date();

		// Filter history to only have client_udpates.
		let history0 = this.history.filter( (h: IHistory) => h.code === 'client_update' );

		// Early data didn't code client_update properly, doing it on the server.
		// Add filter to pull out anything any history item with a time earlier
		// than a previous entry.

		let history: Array<IHistory> = history0.reduce( (accum: Array<IHistory>, h: IHistory) => {
			if(accum.length === 0) {
				// First.
				accum.push(h);
				return accum;
			} else if( ErrorIfUndefined(accum[accum.length-1]).dt < ErrorIfUndefined(h.dt)) {
				// Good!  Add + return.
				accum.push(h);
				return accum;
			} else {
				// dt occurs prior to earlier event. Don't add.
				return accum;
			}
		}, []);

		if(first === null || last === null || history.length < 1) return [];

		let abandoned: any[] = [];
		let last_ms_time = convert_to_date_if_string( history[0].dt ).getTime();
		let current_ms_time = 0;

		for(let i = 0; i < history.length; i++) {
			current_ms_time = convert_to_date_if_string( history[i].dt ).getTime();

			// Add on time
			// Note that stupid freaking clocks sometimes go backwards for an unknown
			// reason.  Not sure why....
			if( current_ms_time - last_ms_time > max_period &&
				current_ms_time - last_ms_time > 0 ) {   
				// Convert to minutes and push.
				abandoned.push( Math.round((current_ms_time - last_ms_time)/(1000*60) ) );
			}
			last_ms_time = current_ms_time;
		}

		return abandoned;
	}

	// Return the time in seconds from server initialization to server completion.
	// Since it only runs on the server side, it can't tell if a user abandons an entry
	// or is actively working. But, for questions that generate no client-side updates, 
	// it is a better option.
	// Has a standard timeout of 5 minutes, in which case null is returned.
	get_server_time_in_seconds(): number | null {
		// Filter history to only have server updates.
		const h_init = this.history.filter( (h: IHistory) => h.code === 'server_initialized' );
		const h_comp = this.history.filter( (h: IHistory) => h.code === 'server_page_completed' );

		if(h_init.length !== 1 || h_comp.length !== 1) return null;

		// @ts-ignore
		const d_init = convert_to_date_if_string( h_init[0].dt ).getTime();
		// @ts-ignore
		const d_comp = convert_to_date_if_string( h_comp[0].dt ).getTime();

		const diff_in_ms = d_comp - d_init;

		// 5 min timeout.
		if(diff_in_ms > 5*60*1000) return null;

		return Math.round( diff_in_ms / 1000 );
	}

	// Returns the time since the first edit until now.
	// Different from editing time, this just considers how long it's been 
	// since they did their first input.
	get_time_in_seconds_since_first_history(): number {
		const first = this.get_first_update_date();
		const current = new Date();

		if(first == null) return 0;

		return Math.round((current.getTime() - first.getTime()) / 1000);
	}

	// Return the time from the first edit to the last edit.
	// Ignores periods with an update longer than 5 minutes.
	get_time_in_seconds(): number {
		const max_period = this.get_max_period_in_ms();
		const first = this.get_first_update_date();
		const last = this.get_last_update_date();

		// Filter history to only have client_udpates.
		let history0 = this.history.filter( (h: IHistory) => h.code === 'client_update' );

		// Early data didn't code client_update properly, doing it on the server.
		// Add filter to pull out anything any history item with a time earlier
		// than a previous entry.
		let history = history0.reduce( (accum: any[], h: IHistory) => {
			if(accum.length === 0) {
				// First.
				accum.push(h);
				return accum;
			} else if( ErrorIfUndefined(accum[accum.length-1]).dt < ErrorIfUndefined(h).dt) {
				// Good!  Add + return.
				accum.push(h);
				return accum;
			} else {
				// dt occurs prior to earlier event. Don't add.
				return accum;
			}
		}, []);

		if(first === null || last === null) return 0;

		let ms = 0;
		let last_ms_time = 0;
		let current_ms_time = 0;

		// Go thru times, adding on periods IF they are positive.
		for(let i = 0; i < history.length; i++) {
			current_ms_time = convert_to_date_if_string( history[i].dt ).getTime();

			// Add on time
			// Note that stupid freaking clocks sometimes go backwards for an unknown
			// reason.  Not sure why....
			if( current_ms_time - last_ms_time <= max_period &&
				current_ms_time - last_ms_time > 0 ) {   
				ms += current_ms_time - last_ms_time;
			}
			last_ms_time = current_ms_time;
		}

		if(ms < 0) {
			// Debug point. Sometimes happens when stupid clocks have times do weird things.
			//throw new Error('invalid time? IfPageSchema ms < zero');
		}

		return Math.round( ms / 1000 );
	}


	// Change the casing of the instructions, description, and help.
	// Loops through, changing the case of anything in a <code> block.
	// Will not change case of anything within "quotes".
	// E.g., 
	//	from:	"Blah <code>=IF(A1="Bob", 1, FALSE)</code> blah
	//	to:		"Blah <code>=if(a1="Bob", 1, false)</code> blah
	// Passing optional_field limits to a single field.  
	standardize_formula_case( optional_field: string = '' ) {
		if(optional_field !== '') {
			// Swap out the given field.
			let s = this[optional_field];
			if(s === null || s === '') return;

			const block_reg = /<code>(.*?)<\/code>/g;
			let matches = s.match(block_reg);

			if(matches && matches !== null) {
				matches.map( (dirty: string) => {
					// Split formula into sections along " (double quotes)
					// Lowercase only if not inside.
					let splits = dirty.split('"');
					for(let i=0; i<splits.length; i++) {
						if( i%2 === 0) splits[i] = splits[i].toLowerCase();
					}
					let clean = splits.join('"');

					// Done!  Replace original.
					s = s.replace(dirty, clean);
				});
			}
			this[optional_field] = s;
			
		} else {
			this.standardize_formula_case('description');
			this.standardize_formula_case('instruction');
			this.standardize_formula_case('helpblock');
			if(typeof this.solution_f !== 'undefined') 
					this.standardize_formula_case('solution_f');
		}
	}



	// Type coercion for ts not liking subtypes.
	toIfPageChoiceSchema(): IfPageChoiceSchema {
		if( this.type !== 'IfPageChoiceSchema') {
			throw new Error('Invalid type convertion to IfPageChoiceSchema');
		}
		// @ts-ignore
		return this;
	}

	toIfPagePredictFormulaSchema(): IfPagePredictFormulaSchema { 
		if( this.type !== 'IfPagePredictFormulaSchema') throw new Error('Invalid type convertion to IfPagePredictFormulaSchema');
		// @ts-ignore
		return this;
	}

	// Type coercion for ts not liking subtypes.
	// Note: allow sub-typing of Harsons to Formula.
	toIfPageFormulaSchema(): IfPageFormulaSchema {
		if( this.type !== 'IfPageFormulaSchema' &&
			this.type !== 'IfPageHarsonsSchema' &&
			this.type !== 'IfPagePredictFormulaSchema' ) throw new Error('Invalid type convertion to IfPageFormulaSchema');
		// @ts-ignore
		return this;
	}
	// Type coercion for ts not liking subtypes.
	toIfPageLongTextAnswerSchema(): IfPageLongTextAnswerSchema {
		if( this.type !== 'IfPageLongTextAnswerSchema') throw new Error('Invalid type convertion to IfPageLongTextAnswerSchema');
		// @ts-ignore
		return this;
	}
	// Type coercion for ts not liking subtypes.
	toIfPageNumberAnswerSchema(): IfPageNumberAnswerSchema {
		if( this.type !== 'IfPageNumberAnswerSchema') throw new Error('Invalid type convertion to IfPageNumberAnswerSchema');
		// @ts-ignore
		return this;
	}
	// Type coercion for ts not liking subtypes.
	toIfPageParsonsSchema(): IfPageParsonsSchema {
		if( this.type !== 'IfPageParsonsSchema') throw new Error('Invalid type convertion to IfPageParsonsSchema');
		// @ts-ignore
		return this;
	}
	// Type coercion for ts not liking subtypes.
	toIfPageTextSchema(): IfPageTextSchema {
		if( this.type !== 'IfPageTextSchema') throw new Error('Invalid type convertion to IfPageTextSchema');
		// @ts-ignore 
		return this;
	}
	// Type coercion for ts not liking subtypes.
	toIfPageShortTextAnswerSchema(): IfPageShortTextAnswerSchema {
		if( this.type !== 'IfPageShortTextAnswerSchema') throw new Error('Invalid type convertion to IfPageShortTextAnswerSchema');
		// @ts-ignore 
		return this;
	}
	// Type coercion for ts not liking subtypes.
	toIfPageHarsonsSchema(): IfPageHarsonsSchema {
		if( this.type !== 'IfPageHarsonsSchema') throw new Error('Invalid type convertion to IfPageHarsonsSchema');
		// @ts-ignore
		return this;
	}
	// Type coercion for ts not liking subtypes.
	toIfPageSliderSchema(): IfPageSliderSchema {
		if( this.type !== 'IfPageSliderSchema') throw new Error('Invalid type convertion to IfPageSliderSchema');
		// @ts-ignore 
		return this;
	}
	// Type coercion for ts not liking subtypes.
	toIfPageSqlSchema(): IfPageSqlSchema {
		if( this.type !== 'IfPageSqlSchema') throw new Error('Invalid type convertion to IfPageSqlSchema');
		// @ts-ignore 
		return this;
	}


}


/*
	This pages displays information to the user.
*/
class IfPageTextSchema extends IfPageBaseSchema {
	client_read!: boolean;

	// Apply json to this obj, signalling no parent classes to do the setting for us.
	constructor( json?: any) {
		super(true);
		if(json === true) return;
		this.initialize(json, this.schema);
		this.updateCorrect();
	}

	get type(): string {
		return 'IfPageTextSchema';
	}

	get schema(): any {
		let inherit = common_schema();

		return {
			...inherit,
			client_read: { type: 'Boolean', initialize: (s: any) => isDef(s) ? bool(s) : false }
		};
	}

	// Remove all client input
	clear_answer_and_all_results(): void {
		this.client_read = false;
		this.correct = false;
		this.completed = false;
		this.client_feedback = [];
	}

	// Has the user provided input?
	client_has_answered(): boolean {
		return this.client_read;
	}

	// Automatically fill in the answer.
	// Used for testing out on the server. 
	debug_answer() {
		this.client_read = true;
		this.updateCorrect();
	}

	// There is no solution for text fields.
	get_solution(): string {
		return '';
	}

	/*
		Update any fields for which user has permissions.
		
		Safe to re-run, with the exception that upon changing client_items, will
		reset this.correct (since we don't know its status).  Will re-run updateCorrect() in 
		case this is on the server and we're updating the object.

		Run upon initial obj creation.
	*/
	updateUserFields(json: any) {
		this._updateUserFields(json, ['client_read']);
	}

	/* 
		Update correct *if* a solution is provided.
		
		Don't updateFeedback, as this type never has feedback rules applied.
	*/
	updateCorrect() {
		if(this.completed) return; // do not update completed items.

		if(!this.client_read) return; // no client submission.

		this.client_feedback = [];
		this.correct = true;
	}

	// Return a nicely formatted view of the client's input. 
	toString(): string {
		return 'read';
	}
}



/*
	Get a single-line piece of information from the user.
*/
class IfPageNumberAnswerSchema extends IfPageBaseSchema {
	client!: number;
	solution!: number;

	// Apply json to this obj, signally no parent classes to do the setting for us.
	constructor( json?: any) {
		super(true);
		if(json === true) return;
		this.initialize(json, this.schema);
		this.updateCorrect();
	}


	get type(): string {
		return 'IfPageNumberAnswerSchema';
	}

	get schema(): any {
		let inherit = common_schema();

		return {
			...inherit,
			client: { type: 'number', initialize: (i: string | null) => isDef(i) && i !== null ? parseFloat(i) : null },
			solution: { type: 'number', initialize: (i: string) => isDef(i) ? parseFloat(i) : null }
		};
	}

	// Has the user provided input?
	client_has_answered(): boolean {
		return this.client !== null;
	}

	// Remove all client input
	clear_answer_and_all_results(): void {
		this.client = 0;
		this.correct = false;
		this.completed = false;
		this.client_feedback = [];
	}
	

	// Automatically fill in the answer.
	// Used for testing out on the server. 
	debug_answer() {
		if(this.solution !== null)  {
			this.client = this.solution;
		} else {
			this.client = Math.round(Math.random()*10);
		}
		this.updateCorrect();
	}

	// Return solution (after changing to string)
	get_solution(): string {
		if(this.solution === null) return '';
		return ''+this.solution;
	}

	/*
		Update any fields for which user has permissions.
		
		Safe to re-run, with the exception that upon changing client_items, will
		reset this.correct (since we don't know its status).  Will re-run updateCorrect() in 
		case this is on the server and we're updating the object.

		Run upon initial obj creation.
	*/
	updateUserFields(json: any ) {
		this._updateUserFields(json, ['client', 'time_limit_expired']);
	}

	/* 
		Update correct *if* a solution is provided.
		
		Don't updateFeedback, as this type never has feedback rules applied.
	*/
	updateCorrect() {
		if(this.completed) return; // do not update completed items.

		if(this.client === null) return; // no client submission.

		this.client_feedback = [];

		// Don't always require having a solution.
		if(this.solution == null || Number.isNaN(this.solution)) {
			this.correct = true;
		} else {
			this.correct = Math.round(this.client*100)/100 === Math.round(this.solution*100)/100;
		}
	}

	// Return a nicely formatted view of the client's input. 
	toString(): string {
		return ''+this.client;
	}

}


// A slider is a more specialized version of the number answer, which uses a range to 
// give the control min/max for a slider control. Does not have a "right" answer.
class IfPageSliderSchema extends IfPageBaseSchema {
	client!: number;
	solution!: number;
	min!: number;
	max!: number;

	// Apply json to this obj, signally no parent classes to do the setting for us.
	constructor( json?: any) {
		super(true);
		if(json === true) return;
		this.initialize(json, this.schema);
	}


	get type(): string {
		return 'IfPageSliderSchema';
	}

	get schema(): any {
		let inherit = common_schema();

		return {
			...inherit,
			client: { type: 'number', initialize: (i: string) => isDef(i) ? parseInt(i, 10) : null },
			solution: { type: 'number', initialize: (i: string) => isDef(i) ? parseInt(i, 10) : null },
			min: { type: 'number', initialize: (i: string) => isDef(i) ? parseInt(i, 10) : 0 }, // default 0
			max: { type: 'number', initialize: (i: string) => isDef(i) ? parseInt(i, 10) : 100 } // default 100
		};
	}

	// Has the user provided input?
	client_has_answered(): boolean {
		return this.client !== null;
	}

	// Remove all client input
	clear_answer_and_all_results(): void {
		this.client = 0;
		this.correct = false;
		this.completed = false;
		this.client_feedback = [];
	}

	// Automatically fill in the answer.
	// Used for testing out on the server. 
	debug_answer() {
		this.client = typeof this.solution !== 'undefined' && this.solution !== null 
			? this.solution
			: Math.round(Math.random()*this.max);
		
		this.updateCorrect();
	}	

	// Return solution (after changing to string)
	get_solution(): string {
		if(this.solution === null) return '';
		return ''+this.solution;
	}


	updateUserFields(json: any) {
		this._updateUserFields(json, ['client', 'time_limit_expired'], true, false);
	}

	/* 
		Update correct *if* a solution is provided.
		Don't updateFeedback, as this type never has feedback rules applied.
	*/
	updateCorrect() {
		if(this.completed) return; // do not update completed items.
		if(this.client === null) return; // no client submission.

		this.client_feedback = [];
		this.correct = true;
	}

	// Return a nicely formatted view of the client's input. 
	toString(): string {
		return ''+this.client;
	}
}



/*
	Get a single-line piece of information from the user.
*/
class IfPageShortTextAnswerSchema extends IfPageBaseSchema {
	client!: string;
	solution!: string;

	// Apply json to this obj, signally no parent classes to do the setting for us.
	constructor( json?: any) {
		super(true);
		if(json === true) return;
		this.initialize(json, this.schema);
		this.updateCorrect();
	}

	get type(): string {
		return 'IfPageShortTextAnswerSchema';
	}

	get schema(): any {
		let inherit = common_schema();

		return {
			...inherit,
			client: { type: 'string', initialize: (s: any) => isDef(s) ? s : '' }
		};
	}

	// Has the user provided input?
	client_has_answered(): boolean {
		return this.client.length > 0;
	}

	// Remove all client input
	clear_answer_and_all_results(): void {
		this.client = '';
		this.correct = false;
		this.completed = false;
		this.client_feedback = [];
	}

	// Automatically fill in the answer.
	// Used for testing out on the server. 
	debug_answer() {
		this.client = 'ShortText';
		this.updateCorrect();
	}

	// No solution, return empty string.
	get_solution(): string {
		return '';
	}

	updateUserFields(json: any) {
		this._updateUserFields(json, ['client', 'time_limit_expired']);
	}

	/* 
		Update correct *if* a solution is provided.
		
		Don't updateFeedback, as this type never has feedback rules applied.
	*/
	updateCorrect() {
		if(this.completed) return; // do not update completed items.

		if(this.client === '') return; // no client submission.

		this.client_feedback = [];
		this.correct = true;
	}

	// Return a nicely formatted view of the client's input. 
	toString(): string {
		return this.client;
	}
}



/*
	Get a multi-line piece of information from the user.
*/
class IfPageLongTextAnswerSchema extends IfPageShortTextAnswerSchema {


	// Apply json to this obj, signally no parent classes to do the setting for us.
	constructor( json?: any) {
		super(true);
		if(json === true) return;
		this.initialize(json, this.schema);
	}

	get type(): string {
		return 'IfPageLongTextAnswerSchema';
	}

	// Automatically fill in the answer.
	// Used for testing out on the server. 
	debug_answer() {
		this.client = 'LongText';
		this.updateCorrect();
	}
	

}



/*
	A page holds a single choice question.

	It can have either a correct choice or a range of choices.
	If a range of choices, then solution should be a wildcard ? or *.
*/
class IfPageChoiceSchema extends IfPageBaseSchema {
	client!: string|null;
	client_items!: Array<string>;
	solution!: string;

	// Apply json to this obj, signally no parent classes to do the setting for us.
	constructor( json?: any) {
		super(true);
		if(json === true) return;
		this.initialize(json, this.schema);
		this.updateCorrect();
	}

	get type(): string {
		return 'IfPageChoiceSchema';
	}

	get schema(): any {
		let inherit = common_schema();

		return {
			...inherit,
			client: { type: 'String', initialize: (s: any) => isDef(s) ? s : null },
			client_items: { type: 'Array', initialize: (a: any[]) => isDef(a) && isArray(a) ? noObjectsInArray(a) : [] },
			// The ? means that any answer is ok.
			solution: { type: 'String', initialize: (s: any) => isDef(s) ? s : '?' },

		};
	}

	// Remove all client input
	clear_answer_and_all_results(): void {
		this.client = null;
		this.correct = false;
		this.completed = false;
		this.client_feedback = [];
	}


	// Has the user provided input?
	client_has_answered(): boolean {
		return this.client !== null;
	}

	// If we have one, return the solution.
	get_solution(): string {
		if(this.solution === '?') return '';
		return this.solution;
	}

	// Automatically fill in the answer.
	// Used for testing out on the server.  Not usable on client side, as 
	// solution will not be present.
	debug_answer() {
		if(typeof this.solution === 'undefined') {
			throw new Error('You can not debug answer without solution being present');
		}
		if(this.solution === '*' || this.solution === '?') {
			this.client = this.client_items[0]; 
		} else {
			this.client = this.solution;
		}
		
		this.updateCorrect();
	}


	updateUserFields(json: any) {
		this._updateUserFields(json, ['client', 'time_limit_expired']);
	}

	/* 
		Update correct *if* a solution is provided.
	*/
	updateCorrect() {
		if(this.completed) return; // do not update completed items.

		if(this.client === null) return; // no client submission.

		// Don't set if there is no available solution
		// Can be either from being on the client w/o (which won't get solutions)
		//		or being on the client or server with no correct answer.
		if(this.solution === null) return;

		// We have a solution and a client submission. set.
		if(this.solution === '?' || this.solution === '*') {
			this.correct = true;			
		} else {
			this.correct = (s(this.client).trim().toLowerCase() === s(this.solution).trim().toLowerCase());
		}
	}

	// Return a nicely formatted view of the client's input. 
	toString(): string {
		return this.client === null ? '' : this.client;
	}

}


/*
	A page holds a single exercise.

	Pages used tests to validate the results of the client_f against solution_f.

	The solution fields are not sent to the client unless they have the _visible tag set.
*/
class IfPageParsonsSchema extends IfPageBaseSchema {
	helpblock!: string;
	potential_items!: Array<string>;
	solution_items!: Array<string>;
	client_items!: Array<string>|null;

	// Apply json to this obj, signally no parent classes to do the setting for us.
	constructor( json?: any) {
		super(true);
		if(json === true) return;
		this.initialize(json, this.schema);
		this.updateCorrect();
	}

	get type(): string {
		return 'IfPageParsonsSchema';
	}

	get schema(): any {
		let inherit = common_schema();

		return {
			...inherit,
			helpblock: { type: 'String', initialize: (s: any) => isDef(s) ? s : null },
			potential_items: { type: 'Array', initialize: (a: any[]) => isDef(a) && isArray(a) ? noObjectsInArray(a) : [] },
			solution_items: { type: 'Array', initialize: (a: any[]) => isDef(a) && isArray(a) ? noObjectsInArray(a) : [] },
			client_items: { type: 'Array', initialize: (a: any[]) => isDef(a) && isArray(a) ? noObjectsInArray(a) : null }, 
			// client_items is set to null to show user hasn't submitted anything yet.

		};
	}

	// Has the user provided input?
	client_has_answered(): boolean {
		return this.client_items !== null && this.client_items.length > 0;
	}

	// Remove all client input
	clear_answer_and_all_results(): void {
		this.clientclient_items = null;
		this.correct = false;
		this.completed = false;
		this.client_feedback = [];
	}


	// Turn solution into a string and return.
	get_solution(): string {
		return this.solution_items.join(', ');
	}

	// Automatically fill in the answer.
	// Used for testing out on the server.  Not usable on client side, as 
	// solution_f will not be present.
	debug_answer() {
		if(typeof this.solution_items === 'undefined' || this.solution_items.length < 1) {
			throw new Error('You can not debug answer without solution_items being present');
		}
		this.client_items = this.solution_items;
		this.updateCorrect();
	}		


	updateUserFields(json: any) {
		this._updateUserFields(json, ['client_items', 'time_limit_expired']);
	}


	/*
		If solutions is available, refresh the this.correct variable.
		If solution tests aren't run (or available), then don't modify this.correct.
	*/
	updateCorrect() {
		// See if the user has submitted anything yet.  If not, then set to null.
		if(this.client_items === null) {
			this.correct = null;
			return;
		}

		// Check to see if we can provide a solution.
		// Solutions are not always available (ie, if we're on the client)
		if(this.solution_items.length < 1) return;

		// Start testing with the assumption of correctness.
		this.correct = true;

		// Check individual answers
		for(let i=0; i<this.solution_items.length && this.correct; i++) {
			this.correct = (this.client_items[i] == this.solution_items[i]);
		}

		this.client_feedback = [];
	}

	// Return a nicely formatted view of the client's input. 
	toString(): string {
		let items = this.client_items === null ? [] : this.client_items.slice().reverse();
		return items.reduce( (accum, item) => item+(accum.length>0 ? ', ': '')+accum, '');
	}

}

// Used for defining an interface for the hot formula parser results.
interface iParseCellValue {
	label: string;
	row: { index: number, label: string, isAbsolute: boolean},
	column: { index: number, label: string, isAbsolute: boolean},
};
interface iParseRangeValue {
	row: { index: number /*, label: string, isAbsolute: boolean */},
	column: { index: number /*, label: string, isAbsolute: boolean */},
};



/*
	A page holds a single exercise.

	Pages used tests to validate the results of the client_f against solution_f.

	The solution fields are not sent to the client unless they have the _visible tag set.
*/
class IfPageFormulaSchema extends IfPageBaseSchema {
	tests!: Array<any>;
	column_titles!: Array<string>;
	column_formats!: Array<string>;

	client_f!: string;
	client_f_format!: string;
	client_test_results!: Array<any>;

	solution_f!: string;
	solution_test_results!: Array<any>;
	solution_f_visible!: boolean;
	solution_test_results_visible!: boolean;

	helpblock!: string;

	// Apply json to this obj, signally no parent classes to do the setting for us.
	constructor( json?: any) {
		super(true);
		
		if(json === true) return;
		
		this.initialize(json, this.schema);
		if(this.solution_test_results.length === 0)	this.updateSolutionTestResults();
		if(this.client_test_results.length === 0) this.updateClientTestResults();
		this.updateCorrect();
	}

	get type(): string {
		return 'IfPageFormulaSchema';
	}

	// Return solution
	get_solution(): string {
		if(this.solution_f === null) return '';
		return this.solution_f;
	}

	// NOTE: This is copied into the child classes. Don't modify w/o updating children.
	get schema(): any {
		let inherit = common_schema();

		return {
			...inherit,

			// Used by this.parse to test client_f against solution_f.
			tests: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? revive_dates_recursively(a) : [] },
			column_titles: { type: 'Array', initialize: (a: any[]) => isDef(a) && isArray(a) ? noObjectsInArray(a) : [] },
			column_formats: { type: 'Array', initialize: (a: any[]) => isDef(a) && isArray(a) ? noObjectsInArray(a) : [] },
			
			client_f: { type: 'Javascript', initialize: (s: any) => isDef(s) ? s : null },
			client_f_format: { type: 'String', initialize: (s: any) => isDef(s) ? s : '' },
			client_test_results: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? a : [] },

			solution_f: { type: 'Javascript', initialize: (s: any) => isDef(s) ? s : null },
			solution_test_results: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? a : [] },

			// Should we show students the results of the solutions, or the solutions themselves?
			solution_f_visible: { type: 'Boolean', initialize: (s: any) => isDef(s) ? bool(s) : false },
			solution_test_results_visible: { type: 'Boolean', initialize: (s: any) => isDef(s) ? s : false },


		};
	}



	// Has the user provided input?
	client_has_answered(): boolean {
		return this.client_f !== null && this.client_f.length > 0;
	}

	// Remove all client input
	clear_answer_and_all_results(): void {
		this.client_f = '';
		this.client_test_results = [];
		this.correct = false;
		this.completed = false;
		this.client_feedback = [];
	}

	// Automatically fill in the answer.
	// Used for testing out on the server.  Not usable on client side, as 
	// solution_f will not be present.
	debug_answer() {
		if(typeof this.solution_f === 'undefined') {
			throw new Error('You can not debug answer without solution_f being present');
		}
		this.client_f = ''+fill_template(this.solution_f, this.template_values); // make sure that we have a string here.
		this.updateCorrect();
	}		


	// Update any fields for which user has permissions.
	// Save to re-run.  Can also run upon initial obj creation.
	updateUserFields(json: any) {

		// Look to see if we have a new client_f. If so, need to update the client parsed results.
		// Empty out the old results. Will be reset by .updateCorrect().
		if(typeof json.client_f !== 'undefined' && json.client_f !== this.client_f) {
			this.client_test_results = [];
		}

		this._updateUserFields(json, ['client_f', 'time_limit_expired', 'hints_parsed', 'hints_viewsolution']);
	}


	// Parse each test with the client code.
	updateClientTestResults() {
		if(this.client_f === null || this.client_f.length < 1) return;
		const client_f = this.client_f;

		this.client_test_results = this.tests.map( t => this.__parse(client_f, t, this.client_f_format));
	}


	/**
		Update solutions. This happens upon creation of a new page.
		Make sure that we actually have a solution before generating anything.
	*/
	updateSolutionTestResults() {
		if(this.solution_f === null || this.solution_f.length < 1) return;

		const solution_f = ''+fill_template(this.solution_f, this.template_values);
		this.solution_test_results = this.tests.map( t => this.__parse(solution_f, t, this.client_f_format) );
	}


	// Refresh the this.correct variable. Relies upon client test results being run.
	// If solution tests aren't run (or available), sets correct to null.
	updateCorrect() {
		// Checks to see if we are set to complete.  If so, do not update.
		// Happens when the server provides us a json with .completed set.
		if(this.completed) return;

		// Create custom feedback.
		//
		// Feedback are not always provided by the server/client, or could just be
		// undefined for the current problem.
		const feedback = get_feedback(this);
		if(feedback !== null) {
			this.client_feedback = feedback;
		}

		// Check to see if we have any input from the user.
		if(this.client_f === null || this.client_f.length < 1) {
			this.correct = null;
			return;
		}
		
		// See if we need to re-generate answers.
		if(this.client_test_results.length === 0 ){
			this.updateClientTestResults();
		}

		// If we don't have a solution, then (i.e., on the client) then just keep the old
		// correct variable.  Don't over-write it, as we may be on the client and
		// are reading out the result of the server's code.
		if(typeof this.solution_f === 'undefined' || this.solution_f === null) {
			return;
		}


		// Start testing with the assumption of correctness.
		this.correct = true;

		// Since we are actually testing, make solution results visible after something
		//  has been submitted and no more changes are possible.
		this.solution_test_results_visible = true;

		// Feedback may be null if we're on the client, not server.
		// But, if it's not null, and longer than zero, then this is not correct.
		if(typeof this.client_feedback !== 'undefined' && this.client_feedback !== null ) {
			this.correct = (this.client_feedback.length === 0);
			if(!this.correct) return;
		}

		// Check individual answers
		for(let i=0; i<this.client_test_results.length && this.correct; i++) {

			if(typeof this.client_test_results[i].result === 'string') {
				// String.
				this.correct = (
					this.client_test_results[i].result == 
					this.solution_test_results[i].result
					);
			} else {
				// Number.
				// Rounds to 2 decimal points to avoid problems with floating point numbers.
				this.correct = (
					Math.round(this.client_test_results[i].result * 100) == 
					Math.round(this.solution_test_results[i].result * 100)
					);
			}
		}
		if(!this.correct) return;

		// Check to make sure that there are no errors. If any errors, fail.
		this.correct = (0 === this.client_test_results.filter( t => t.error !== null ).length);

		if(!this.correct) return;


	}

	/*
		Utility function to fix issues with parser function
	*/
	__clean_parse_formula( dirty_formula: string ): string {
		let d = /\d/;
		let formula = dirty_formula.trim(); 

		// Issue 1: Parser doesn't work with .1, so change to 0.1
		
		// Go through the formula, testing to see if we have a non-digit . digit pattern.
		// JS doesn't have look behinds, so we can't use a regex like a normal human.
		// E.g., (?!\D\).(?=\d) pattern
		for(let i=0; i < formula.length; i++) {
			if(	formula.substr(i,1) === '.' && 
				d.test(formula.substr(i+1,1)) &&  
				formula.substr(i+1,1).length > 0 &&
				!d.test(formula.substr(i-1, 1))
				) {
				// Found an example!  Clean by inserting a leading zero.
				formula = formula.slice(0, i) + '0' + formula.substr(i);

			}
		}

		// Issue 2: Excel isn't case sensitive, but string comparisons with parser are.
		// Lowercase everything.
		// @TODO: This was removed to make comparisons case sensitive again.
		//formula = formula.toLowerCase();


		// Issue 3: Change true=>TRUE, and false=>FALSE.
		// Note that this has to happen after toLowerCase.
		formula = formula.replace( /true/ig, 'TRUE').replace( /false/ig, 'FALSE');


		// Issue 4: Excel doesn't like single-quotes ' but parser is ok with it.
		// Change formula to one that generates an error.
		if(formula.match(/'/) !== null) {
			formula = '=1/';
		}


		return formula;
	}

	/*
		Parse out given string.
		Returns results.

		No side effects.
		
		Code can be run on both client and server, with and without correct results.
		Cell references in the raw data must be lowercase.
		Documentation: https://www.npmjs.com/package/hot-formula-parser

		@arg formula
	*/
	__parse(formula: string, current_test: any, s_format: string): any {

		// Update test results.
		//let columns = Object.keys(this.tests[0]);
		let parser = new FormulaParser();

		let res: { result: string|null, error: string|null } = { 
				result: null,
				error: null
			};

		// Get int position of a letter.
		let i_to_alpha = (i: number): string => 'abcdefghijklmnopqrstuvwxyz'.substr(i, 1);

		// Add a hook for getting variables.
		// Requires that all passed coordinates are on the first row.
		parser.on('callCellValue', function(cellCoord: iParseCellValue, done: any) {

			if( typeof cellCoord.label == 'undefined') throw new Error('Error, should be a .label here?');

 			let coor = cellCoord.label.toLowerCase();
			if(coor.substr(1) !== '1') {
				throw new Error('#REF '+ coor);
			}
			if(typeof current_test[coor.substr(0,1)] === 'undefined') {
				throw new Error('#REF' + coor);
			}
			let res = current_test[coor.substr(0,1)];
			done(typeof res ==='string' ? res /*. NDG toLowerCase()*/ : res );
		});


		// Add hook for getting ranges.
		parser.on('callRangeValue', function(startCellCoord: iParseRangeValue, endCellCoord: iParseRangeValue, done: any) {
			let fragment: any[] = [];

			if( typeof startCellCoord.row == 'undefined') throw new Error('Error, should be .row.index here');
			if( typeof endCellCoord.row == 'undefined') throw new Error('Error, should be .row.index here');

			if(startCellCoord.row.index !== 0 || endCellCoord.row.index !== 0) {
				throw new Error('#REF');
			}

			// Disabled code useful for vertical ranges.
			//for (var row = startCellCoord.row.index; row <= endCellCoord.row.index; row++) {
			//	var rowData = data[row];
			//	var colFragment: any[] = [];

			for (var col = startCellCoord.column.index; col <= endCellCoord.column.index; col++) {
				if( typeof current_test[ i_to_alpha(col) ] !== 'undefined') {
					fragment.push( current_test[ i_to_alpha(col) ] );
				} else {
					throw new Error('#REF');
				}
			}
			//	fragment.push(colFragment);
			//}

			//if (fragment) {
				done(fragment);
			//}
		});

		// Clean-up stuff that causes an error.
		let clean_formula = this.__clean_parse_formula(formula);

		// Require formulas must start with `=`.  
		if(clean_formula !== null && clean_formula.substr(0,1) === '=') {
			try {
				res =  parser.parse(clean_formula.substr(1));  // Parser doesn't want a starting `=`
			} catch(e) {
				res.error = '#ERROR!';
			}
		}


		// See if the result is a date.  If so, go ahead and transform it according to the
		// given format code. Otherwise, we get the default toString behavior, which gives us
		// a string like '2018-10-04T16:12:12.345Z'.
		if( s_format === 'shortdate' 
				&& typeof res.result !== 'undefined'
				&& res.result !== null
				&& typeof res.result === 'object' 
				/* @ts-ignore */
				&& typeof res.result.toLocaleDateString !== 'undefined') {
			// @ts-ignore
			res.result = res.result.toLocaleDateString('en-US');
		}


		// Go through results and round any floating point numbers
		// to 2 decimal points.
		if(typeof res.result === 'number' && !isInteger(res.result)) {
			res.result = ''+Math.round(res.result*100)/100;
		}

		return res;
	}



	// Return a nicely formatted view of the client's input. 
	// Don't return nulls but instead an empty string.
	toString(): string {
		return this.client_f === null ? '': this.client_f;
	}
}




/*
	This page holds a single exercise for formula using a horizontal parsons (harsons).
	It's the same as the FormulaSchema, with the addition of a toolbox.
*/
class IfPageHarsonsSchema extends IfPageFormulaSchema {
	toolbox!: Array<string>;


	// Apply json to this obj, signally no parent classes to do the setting for us.
	constructor( json?: any) {
		super(true);

		if(json === true) return;
		
		this.initialize(json, this.schema);
		if(this.solution_test_results.length === 0)	this.updateSolutionTestResults();
		if(this.client_test_results.length === 0) this.updateClientTestResults();
		this.updateCorrect();
	}

	get type(): string {
		return 'IfPageHarsonsSchema';
	}

	// NOTE: This is copied from the parent class. Don't modify w/o updating parent.
	get schema(): any {
		let inherit = common_schema();

		return {
			...inherit,

			// Used by this.parse to test client_f against solution_f.
			tests: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? revive_dates_recursively(a) : [] },
			column_titles: { type: 'Array', initialize: (a: any[]) => isDef(a) && isArray(a) ? noObjectsInArray(a) : [] },
			column_formats: { type: 'Array', initialize: (a: any[]) => isDef(a) && isArray(a) ? noObjectsInArray(a) : [] },
			
			client_f: { type: 'Javascript', initialize: (s: any) => isDef(s) ? s : null },
			client_f_format: { type: 'String', initialize: (s: any) => isDef(s) ? s : '' },
			client_test_results: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? a : [] },

			solution_f: { type: 'Javascript', initialize: (s: any) => isDef(s) ? s : null },
			solution_test_results: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? a : [] },

			// Should we show students the results of the solutions, or the solutions themselves?
			solution_f_visible: { type: 'Boolean', initialize: (s: any) => isDef(s) ? bool(s) : false },
			solution_test_results_visible: { type: 'Boolean', initialize: (s: any) => isDef(s) ? s : false },

			// What blockly blocks should be included?
			// This should be an array of strings.
			toolbox: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? a : [] }
		};
	}
}


/**
	Holds a sub-type of formula page.
	Used to trigger different interface
*/
class IfPagePredictFormulaSchema extends IfPageFormulaSchema {
	predicted_answers_used!: Array<number>;


	// Apply json to this obj, signally no parent classes to do the setting for us.
	constructor( json?: any) {
		super(true);

		if(json === true) return;

		this.initialize(json, this.schema);
		if(this.solution_test_results.length === 0)	this.updateSolutionTestResults();
		if(this.client_test_results.length === 0) this.updateClientTestResults();
		this.updateCorrect();
	}

	get type(): string {
		return 'IfPagePredictFormulaSchema';
	}
	

	// NOTE: This is copied from the parent class. Don't modify w/o updating parent.
	get schema(): any {
		let inherit = common_schema();

		return {
			...inherit,

			// Used by this.parse to test client_f against solution_f.
			tests: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? revive_dates_recursively(a) : [] },
			column_titles: { type: 'Array', initialize: (a: any[]) => isDef(a) && isArray(a) ? noObjectsInArray(a) : [] },
			column_formats: { type: 'Array', initialize: (a: any[]) => isDef(a) && isArray(a) ? noObjectsInArray(a) : [] },
			
			client_f: { type: 'Javascript', initialize: (s: any) => isDef(s) ? s : null },
			client_f_format: { type: 'String', initialize: (s: any) => isDef(s) ? s : '' },
			client_test_results: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? a : [] },

			solution_f: { type: 'Javascript', initialize: (s: any) => isDef(s) ? s : null },
			solution_test_results: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? a : [] },

			// Should we show students the results of the solutions, or the solutions themselves?
			solution_f_visible: { type: 'Boolean', initialize: (s: any) => isDef(s) ? bool(s) : false },
			solution_test_results_visible: { type: 'Boolean', initialize: (s: any) => isDef(s) ? s : false },

			// What answers should be shown to the user to match?
			// This should be an array of strings.
			predicted_answers_used: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? a : [] },
		};
	}
	
	// Loop through predictions to see if the correct items have been chosen.
	predictions_correct(): boolean {
		let answer_index = -1;
		//if(this.predicted_answers_correct.length !== this.tests.length) 
		//		throw new Error("Predicted answers not equal to tests in IfPagePredictFormulaSchema");

		// If the lengths don't match, then we haven't initialized this by the client yet.
		if(this.predicted_answers_used.length !== this.solution_test_results.length) return false;

		// Go through the items to make sure that the numbers match the current row.
		// Note that we can have duplicate items, in which case we don't care about the row index,
		// 	but instead about the actual value for each row.
		for(let i=0; i<this.predicted_answers_used.length; i++) {
			answer_index = this.predicted_answers_used[i];

			// Unset answer option.
			if(answer_index === -1 ) return false; 

			// See if the answer for the given index matches the answer
			// for that row. 
			if(	this.solution_test_results[answer_index].result !== 
				this.solution_test_results[i].result) return false;
		}
		return true;
	}


	// Update fields. Note that super will generate a history object.
	updateUserFields(json: any) {
		// Look to see if we have a new client_f. If so, need to update the client parsed results.
		// Empty out the old results. Will be reset by .updateCorrect().
		if(typeof json.client_f !== 'undefined' && json.client_f !== this.client_f) {
			this.client_test_results = [];
		}

		this._updateUserFields(json, 
				['client_f', 'predicted_answers_used', 
				'time_limit_expired', 'hints_parsed', 'hints_viewsolution']);
	}
}


/**
 * SqlSchema requires a more complex instantiation. Solution Results must be set
 * in the json object. This keeps any parsing / sql code out of this schema file.
 */
class IfPageSqlSchema extends IfPageBaseSchema {

	// Data
	t1_name!: string
	t1_titles!: Array<string>
    t1_formats!: Array<string>
	t1_rows!: Array<any>

	t2_name!: string
	t2_titles!: Array<string>
    t2_formats!: Array<string>
	t2_rows!: Array<any>

	t3_name!: string
	t3_titles!: Array<string>
    t3_formats!: Array<string>
	t3_rows!: Array<any>

	// Problem
	client_sql!: string;
	client_results_titles!: string[] | null;
	client_results_rows!: any[] | null;
	client_feedback!: string[];

	solution_sql!: string;
	solution_results_titles!: string[];
	solution_results_formats!: string[];
	solution_results_rows!: any[];
	solution_sql_visible!: boolean;
	solution_results_visible!: boolean;

	helpblock!: string;

	// Apply json to this obj, signaling no parent classes to do the setting for us.
	constructor(json?: any) {
		super(true);
		
		if(json === true) return;
		
		this.initialize(json, this.schema);
		this.updateCorrect();
	}

	get type(): string {
		return 'IfPageSqlSchema';
	}

	get_solution(): string {
		if(this.solution_sql === null) return '';
		return this.solution_sql;
	}

	get schema(): any {
		let inherit = common_schema();

		return {
			...inherit,

			t1_name: { type: 'String', initialize: (s: any) => isDef(s) ? s : null },
			t1_titles: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? a : [] },
			t1_formats: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? a : [] },
			t1_rows: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? a : [] },

			t2_name: { type: 'String', initialize: (s: any) => isDef(s) ? s : null },
			t2_titles: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? a : [] },
			t2_formats: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? a : [] },
			t2_rows: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? a : [] },

			t3_name: { type: 'String', initialize: (s: any) => isDef(s) ? s : null },
			t3_titles: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? a : [] },
			t3_formats: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? a : [] },
			t3_rows: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? a : [] },

			helpblock: { type: 'String', initialize: (s: any) => isDef(s) ? s : null },

			client_sql: { type: 'String', initialize: (s: any) => isDef(s) ? s : null },
			client_results_rows: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? a : [] },
			client_results_titles: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? a : [] },
			client_feedback: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? a : null },

			solution_sql: { type: 'String', initialize: (s: any) => isDef(s) ? s : null },
			solution_results_rows: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? a : [] },
			solution_results_titles: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? a : [] },
			solution_results_formats: { type: 'Array', initialize: (a: any) => isDef(a) && isArray(a) ? a : [] },

			// Should we show students the results of the solutions, or the solutions themselves?
			solution_sql_visible: { type: 'Boolean', initialize: (s: any) => isDef(s) ? bool(s) : false },
			solution_results_visible: { type: 'Boolean', initialize: (s: any) => isDef(s) ? s : false }

		};
	}

	// Get a list of all tables
	get_all_table_names() {
		const names: string[] = [];

		if(this.t1_name !== null ) {
			names.push(this.t1_name);
		}
		if(this.t2_name !== null ) {
			names.push(this.t2_name);
		}
		if(this.t3_name !== null ) {
			names.push(this.t3_name);
		}
		return names;
	}

	// Get a list of all column names
	get_all_column_names(filter: string = '') {
		
		if(filter === '') {
			return [ 
				...this.get_all_column_names('t1'), 
				...this.get_all_column_names('t2'), 
				...this.get_all_column_names('t3'),
				...this.get_all_column_names('solution') ].filter(onlyUnique);
		} 

		if(filter === 't1' && this.t1_titles !== null ) {
			return [ ...this.t1_titles ]
		}
		if(filter === 't2' && this.t2_titles !== null ) {
			return [ ...this.t2_titles ]
		}
		if(filter === 't3' && this.t3_titles !== null ) {
			return [ ...this.t3_titles ]
		}
		if(filter === 'solution' && this.solution_results_titles !== null) {
			return [ ...this.solution_results_titles ];
		}
		return [];

	}

	// Guess about column format based on solution and t1, t2, ... 
	get_column_formats_based_on_title(titles: string[], default_format: string = 'text'): string[] {
		const formats: string[] = [];
		let match: string | null = null;

		// Should only happen on client-side, not server.
		if(typeof window === 'undefined') throw new Error('get_column_formats_based_on_title');

		const get_title = (title: string, titles: string[], formats: string[]): string | null => {
			let match_i = -1;
			match_i = titles.findIndex( arg => arg.toLowerCase() === title.toLowerCase());
			if(match_i > -1) {
				return formats[match_i];
			} else {
				return null;
			}
		};

		// Go through each title, looking for a match.
		for(let i=0; i < titles.length; i++) {
			match = null;
			
			// Try looking in solutions formats
			if(typeof this.solution_results_formats !== 'undefined' && this.solution_results_formats.length > 0) {
				match = get_title(titles[i], this.solution_results_titles, this.solution_results_formats);
			}

			if(match === null && typeof this.t1_formats !== 'undefined' && this.t1_formats.length > 0) {
				match = get_title(titles[i], this.t1_titles, this.t1_formats);
			} 

			if(match === null && typeof this.t2_formats !== 'undefined' && this.t2_formats.length > 0) {
				match = get_title(titles[i], this.t2_titles, this.t2_formats);
			} 

			if(match === null && typeof this.t3_formats !== 'undefined' && this.t3_formats.length > 0) {
				match = get_title(titles[i], this.t3_titles, this.t3_formats);
			} 

			// Add found column format or the default
			if(match === null ) {
				formats.push(default_format);
			} else {
				formats.push(match);
			}
		} 

		// Logic check to make sure that we have a format for each column.
		if( formats.filter( f => typeof f === 'undefined').length > 0) {
			//onsole.log(formats, titles);
			throw new Error('Invalid undefined result for get_column_formats_based_on_title');
		}

		return formats;
	}


	

	// Has the user provided input?
	client_has_answered(): boolean {
		return this.client_sql !== null && this.client_sql.length > 0;
	}

	// Remove all client input
	clear_answer_and_all_results(): void {
		this.client_sql = '';
		this.correct = false;
		this.completed = false;
		this.client_results_rows = null;
		this.client_results_columns = null;
	}
	

	// Automatically fill in the answer.
	// Used for testing out on the server.  Not usable on client side, as 
	// solution_sql will not be present.
	debug_answer() {
		if(typeof this.solution_sql === 'undefined') {
			throw new Error('You can not debug answer without solution_sql being present');
		}
		this.client_sql = '' + fill_template(this.solution_sql, this.template_values); // make sure that we have a string here.
		this.client_results_rows = null;
		this.client_results_titles = null;
		this.updateCorrect(); // sets correct to null
	}		


	// Update any fields for which user has permissions.
	// Save to re-run.  Can also run upon initial obj creation.
	updateUserFields(json: any) {

		// Look to see if we have a new client_sql. If so, need to update the client parsed results.
		// Empty out the old results. 
		if(typeof json.client_sql !== 'undefined' && json.client_sql !== this.client_sql) {
			this.client_results_rows = null;
			this.client_results_titles = null;
		}
		this._updateUserFields(json, ['client_sql', 'time_limit_expired', 'hints_parsed', 'hints_viewsolution']);
	}



	// Refresh the this.correct variable. Relies upon client and solution results being run.
	// If solution aren't run (or available), sets correct to null.
	// Requires that queryFactory sets the client_results_rows and titles prior to being run.
	updateCorrect() {
		// Checks to see if we are set to complete.  If so, do not update.
		// Happens when the server provides us a json with .completed set.
		if(this.completed) return;

		// Create custom feedback.
		//
		if(this.client_feedback == null) {
			this.client_feedback = [];
		}
		
		// First see if the server has any feedback. If so, don't run on client
		// This is important, as we don't want to wipe out server feedback by running it locally.
		if(this.client_feedback.length == 0) {
			const feedback = get_feedback_sql(this);
			if(feedback !== null) {
				this.client_feedback = feedback;
			}
		}

		// Check to see if we have any input from the user.
		if(this.client_sql === null || this.client_sql.length < 1) {
			this.correct = null;
			return;
		}
		
		// See if we need to re-generate client answers. If so, pop out, as sql results
		// must be set externally by queryFactory.
		if(this.client_results_rows === null || this.client_results_rows.length === 0){
			this.correct = null;
			return;
		}
		if(this.client_results_titles === null || this.client_results_titles.length === 0){
			this.correct = null;
			return;
		}

		// See if we need to re-generate solution answers. If so, error out, as sql results
		// must be set externally by queryFactory
		if(this.solution_results_titles.length === 0 ){
			throw new Error('IfPageSchemas.SQL updateCorrect should not have empty solution results');
		}

		// Start testing with the assumption of correctness.
		this.correct = true;

		// Since we are actually testing, make solution results visible after something
		//  has been submitted and no more changes are possible.
		this.solution_results_visible = true;

		// Feedback may be null if we're on the client, not server.
		// But, if it's not null, and longer than zero, then this is not correct.
		// Mark as false, and don't do any more checks.
		if(typeof this.client_feedback !== 'undefined' && this.client_feedback !== null ) {
			this.correct = (this.client_feedback.length === 0);
			if(!this.correct) return;
		}

		// Check column titles
		if(this.client_results_titles.length !== this.solution_results_titles.length) {
			this.correct = false;
			this.client_feedback.push('You have a different number of columns than the solution. Check your SELECT.');
			return;
		}
		for(let i=0; i<this.client_results_titles.length; i++) {
			if( this.client_results_titles[i].toLowerCase() !== this.solution_results_titles[i].toLowerCase()) {
				this.correct = false;
				this.client_feedback.push('Your column ' + this.client_results_titles[i] +
					 ' does not match the solution column ' + this.solution_results_titles[i] );
				return;
			}
		}

		// Check Rows
		if(this.client_results_rows.length !== this.solution_results_rows.length) {
			this.correct = false;
			this.client_feedback.push('You have a different number of rows than the solution. Check your WHERE / ON commands.');
			return;
		}


		// Compare each value in client v. server solution.
		// Relies upon the same sort being set in client and solution.
		// Should be done by the SQL code, which will automatically sort by field values (0...n) if
		// no order by is given.
		for(let i=0; i < this.client_results_rows.length; i++) {
			for(let j=0; j < this.client_results_rows[i].length; j++) {
				if( typeof this.client_results_rows[i][j] === 'string' || this.client_results_rows[i][j] === null) {
					// string or null comparison 
					
					if( this.client_results_rows[i][j] !== this.solution_results_rows[i][j]) {
						this.correct = false;
						this.client_feedback.push('At least one value in ' + this.client_results_titles[i] +
							' does not match the solution. (' + this.client_results_rows[i][j] + ' != ' + this.solution_results_rows[i][j] +')' );
						return;
					}

				} else {
					// numeric, check for floating point issue.
					if( 	Math.round(this.client_results_rows[i][j]*100) !== 
							Math.round(this.solution_results_rows[i][j]*100) ) {
						this.correct = false;
						this.client_feedback.push('At least one value in ' + this.client_results_titles[i] +
							' does not match the solution. (' + this.client_results_rows[i][j] + ' != ' + this.solution_results_rows[i][j] +')' );
						return;
					}

				}
			}
		}

		// Reached the end, no issues found. Assume that it is still correct.
		if(!this.correct) throw new Error('Invalid end state for SqlPageSchema.updateCorrect');
	}


	// Return a nicely formatted view of the client's input. 
	// Don't return nulls but instead an empty string.
	toString(): string {
		return this.client_sql === null ? '': this.client_sql;
	}
}





// Used to correctly instantiate a class based on the json .type property.
function get_page_schema_as_class(json: any): IfPageBaseSchema {
	const type = json.type;
	// @ts-ignore
	let p = {
		'IfPageTextSchema': IfPageTextSchema,
		'IfPageChoiceSchema': IfPageChoiceSchema,
		'IfPageFormulaSchema': IfPageFormulaSchema,
		'IfPageParsonsSchema': IfPageParsonsSchema,
		'IfPageHarsonsSchema': IfPageHarsonsSchema,
		'IfPageNumberAnswerSchema': IfPageNumberAnswerSchema,
		'IfPageSliderSchema': IfPageSliderSchema,
		'IfPageShortTextAnswerSchema': IfPageShortTextAnswerSchema,
		'IfPageLongTextAnswerSchema': IfPageLongTextAnswerSchema,
		'IfPagePredictFormulaSchema': IfPagePredictFormulaSchema,
		'IfPageSqlSchema': IfPageSqlSchema,
	}[type];

	if(typeof p === 'undefined') {
		throw new Error('Invalid type passed to IfPageSchemas.get_page_schema_class');
	}

	return new p(json);
}



/**
	Class used to quickly transmit essential information to summarize the success / failure of a page.
	Used to reduce the amount of data to be transmitted across the wire when profs want to see
	a quick summary of how their class is doing.
 */
class IfPageAnswer {
    username!: string;
	level_id!: string;
    level_code!: string; // level code, math1, math2, etc...
    sequence_in_level!: number;
    kcs_as_string!: string; // comma delimited list.
    answers!: Array<string>;
    solution!: string;
    solution_pretty!: string;
    correct!: boolean | null;
    seconds!: number;
    classification!: string;

	page_code!: string; // page code, such as test, tutorial, ...
	
	constructor(json?: any) {
		if(typeof json === 'undefined') return;
		
		for(let key in json) {
			// eslint-disable-next-line no-prototype-builtins
			if(json.hasOwnProperty(key)) {
				// @ts-ignore
				this[key] = json[key];
			}
		}
	}
}


// Initialize from a page.
function build_answers_from_level( level: IfLevelSchema ): Array<IfPageAnswer> {
    const username = level.username;
    const answers: IfPageAnswer[] = [];
    let a: IfPageAnswer;

    level.pages.forEach( (p,i)=> {
        if(p.type !== 'IfPageFormulaSchema' && p.type !== 'IfPageHarsonsSchema') return;

        a = new IfPageAnswer();
        a.level_code = level.code;
		a.level_id = level._id;
        a.username = username;
        a.sequence_in_level = i;
        a.kcs_as_string = p.kcs.join(',');
        a.solution = p.get_solution();
        a.solution_pretty = ''+fill_template( a.solution, p.template_values );
        a.correct = p.correct;
        a.seconds = p.get_time_in_seconds();
		a.page_code = p.code;
		a.sequence_in_level = i;

        // Grab all of the answers in the given page and return as an array of an array of strings.
        // [  ['=1', '=23'], ['=32'], ...]
        const non_intermediate_histories = typeof p.history  === 'undefined' || p.history.length == 0 
            ? []
            : p.history.filter( (history: { tags: { filter: (arg0: (t: any) => boolean) => { (): any; new(): any; length: number; }; }; client_f: any; }) => {
                if( typeof history.tags === 'undefined') return false;

                // If this history has an INTERMEDIATE, no!
                if( history.tags.filter( (t: { tag: string; }) => t.tag === 'INTERMEDIATE' ).length !== 0)  return false;

                // Only give histories for thing we understand, like client_f
                if( typeof history.client_f === 'undefined') return false;

                return true;
            });
        
        a.answers = non_intermediate_histories.map( (h: { client_f: any; }) => h.client_f );
        a.classification = !p.correct 
            ? 'Incorrect' 
            : a.seconds > 60 ? 'Correct, but slow' : 'Correct';
        
        answers.push(a);
    });

    return answers;
}

export {
	get_page_schema_as_class,
	IfPageBaseSchema,
	IfPageTextSchema,
	IfPageChoiceSchema,
	IfPageFormulaSchema,
	IfPageParsonsSchema,
	IfPageHarsonsSchema,
	IfPagePredictFormulaSchema,
	IfPageNumberAnswerSchema,
	IfPageSliderSchema,
	IfPageShortTextAnswerSchema,
	IfPageLongTextAnswerSchema,
	IfPageAnswer,
	IfPageSqlSchema,
	build_answers_from_level,
};

